@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

body{
    font-family: 'Montserrat', sans-serif;
    background: #F7F8FC;
}
ul, li{
    list-style: none;
    padding: 0;
    margin: 0;
}
a:hover{
    text-decoration: none;
}
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1A237E;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.home-banner {
    background: url("../../public/images/banner.png");
    background-position: center;
    background-size: cover;
    padding: 120px 0 20px 0;
}
.inner-banner {
    background: url("../../public/images/banner.png");
    background-position: center;
    background-size: cover;
    padding: 148px 0 49px 0;
    color: #fff;
    text-align: center;
}
.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 15px 0;
}
.inner-header {
    display: flex;
    justify-content: space-between;
}
.inner-header-lft {
    flex: 1;
}
.inner-header-rht {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1 1;
    justify-content: flex-end;
}
.inner-header-rht h4 {
    margin: 0;
    font-size: 14px;
    color: #fff;
    font-weight: 300;
}

.rbtn{
    color: #f2cd49;
    margin: 0;
    font-size: 14px;;
    font-weight: 300;
}
.themeBtn {
    display: inline-block;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    border-radius: 3px;
}
.inner-header-lft h3 {
    margin: 0;
    color: #fff;
    font-size: 34px;
    font-weight: 600;
}

img.man-icon {
    position: absolute;
    bottom: 0;
    left: -141px;
    right: 0;
    width: 271px;
    margin: 0 auto;
}
.home-banner h3 {
    font-size: 144px;
    font-weight: 800;
    color: #fff;
    margin: 0;
}
.home-banner h5 {
    margin: 0;
    font-size: 36px;
    color: #fff;
    font-weight: 600;
}
.home-banner h6 {
    font-size: 49px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 20px 0;
}
.use-process {
    padding: 70px 0;
    background: #fff;
}
.use-process h2 {
    text-align: center;
    color: #171717;
    font-size: 35px;
    font-weight: 300;
    margin: 0 0 48px 0;
}
.step-list {
    text-align: center;
    margin-bottom: 30px;
    padding: 22px 0 0 0;
}
.step-list img {
    height: 158px;
}
.step-list h3 {
    color: #2a2a2a;
    font-size: 19px;
    font-weight: 600;
    margin: 24px 0 15px 0;
}
.step-list p {
    margin: 0;
    color: #949494;
    font-size: 14px;
}
.step-list span {
    position: absolute;
    top: 0;
    left: 24px;
    font-size: 36px;
    font-weight: 700;
}
.about-sec {
    background: #f5f5f5;
    padding: 50px 0;
}
.about-sec h3 {
    color: #505050;
    font-size: 35px;
    font-weight: 500;
    text-transform: uppercase;
}
.about-sec p {
    color: #505050;
    font-size: 15px;
    font-weight: 300;
}
.intro-sec {
    padding: 50px 0;
    background: #fff;
}
.intro-sec h3 {
    color: #505050;
    font-size: 35px;
    font-weight: 500;
    text-transform: uppercase;
}
.intro-sec p {
    color: #505050;
    font-size: 15px;
    font-weight: 300;
}
.intro-inner {
    width: 500px;
    margin: 0 auto 49px auto;
    text-align: center;
    max-width: 100%;
}
.intro-sec h2 {
    color: #373737;
    font-size: 39px;
    font-weight: 700;
}
.info-sec{
    background: url("../../public/images/info-bg.png");
    background-position: center;
    background-size: cover;
    padding: 60px 0 0px 0;
}
.info-sec ul {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
}
.info-sec h2 {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}
.info-sec h1 {
    font-size: 107px;
    color: #fff;
    font-weight: 600;
}
.info-sec ul {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
    margin-bottom: 40px;
}
.info-sec ul li {
    position: relative;
    padding: 0 0 0 58px;
}
.info-sec ul li span {
    position: absolute;
    left: 0;
    top: 0;
}
.info-sec ul li h6 {
    color: #fff;
    font-size: 19px;
    margin: 0;
}
.info-sec ul li b {
    color: #ffd740;
    font-size: 19px;
}
.partners-sec {
    padding: 60px 0;
    text-align: center;
    background: #fff;
}
.partners-sec h2 {
    font-size: 42px;
    font-weight: 600;
    margin: 0 0 48px 0;
    text-transform: uppercase;
}
.partners-sec ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    justify-content: center;
}
.partners-sec ul li img {
    height: 72px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
}
.footer {
    background: url("../../public/images/footer-bg.png");
    background-position: center;
    background-size: cover;
    padding: 70px 0 0 0;
}
.footer-top {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 44px;
}
.footer-top button {
    background: transparent;
    border: none;
    padding: 0;
}
.footer-top h4 {
    color: #4caf50;
    font-size: 24px;
    margin: 3px 0 0 0;
    text-align: left;
    font-weight: 500;
}
.footer-widget p {
    color: #b3b3b3;
    font-size: 15px;
    font-weight: 300;
    margin: 16px 0 14px 0;
}
ul.footer-social-l {
    display: flex;
    gap: 17px !important;
    column-count: inherit !important;
}
ul.footer-social-l a {
    color: #fff;
}
.footer-widget h3 {
    color: #fff;
    font-size: 23px;
    font-weight: 600;
    margin: 0 0 17px 0;
    padding: 0 0 8px 0;
    position: relative;
}
.footer-widget h3::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 28px;
    height: 2px;
    background: #1475f7;
}
.footer-widget ul {
    column-count: 2;
    gap: 30px;
}
.footer-widget ul li a {
    color: #b3b3b3;
    font-size: 14px;
    font-weight: 300;
    display: block;
    padding: 4px 0;
}
.footer-widget ul li a:hover{
    color: #fff;
}
p.copyRight {
    margin: 0;
    color: #b3b3b3;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    padding: 30px 0;
}
.testimonail-sec {
    background: #f8f9fb;
    padding: 100px 0;
}
.testim-l label {
    background: #fff;
    box-shadow: 0 0 10px 3px rgba(0,0,0,.125);
    border-radius: 30px;
    padding: 6px 20px;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 21px 0;
}
.testim-l h3 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
}
.testim-l p {
    color: #606275;
    font-size: 15px;
    line-height: 1.6;
}
.testimonial-box {
    background: #fff;
    padding: 30px;
}
.testimonial-box i.fas.fa-star {
    color: #ffcc00;
    font-size: 14px;
    margin-right: 3px;
}
.testimonial-box p {
    color: #6f7081;
    font-size: 19px;
    line-height: 1.7;
}
.testi-pro {
    display: flex;
    align-items: center;
    gap: 20px;
}
.testi-pro-img {
    width: 70px;
    height: 70px;
    border: 3px solid #1381eb;
    border-radius: 50%;
}
.testi-pro-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 3px;
}
.testi-small-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    z-index: -1;
}
.testim-r {
    position: relative;
    z-index: 1;
}
.testi-small-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.testim-r ul li:first-child .testi-small-img {
    top: -45px;
    left: -66px;
    width: 100px;
    height: 100px;
}

.testim-r ul li:nth-child(2) .testi-small-img {
    top: -38px;
    right: -24px;
    left: auto;
}

.testim-r ul li:nth-child(3) .testi-small-img {
    top: -80px;
    right: 0;
    left: 0;
    margin: 0 auto;
    animation: zoom-in-zoom-out 5s ease-out infinite;
}

.testim-r ul li:nth-child(4) .testi-small-img {
    top: 163px;
    right: -86px;
    left: auto;
    animation: zoom-in-zoom-out 4s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  img.banner-circle {
    animation: ani 55s linear  infinite;
    width: 100%;
}
@keyframes ani {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .common-auth {
    background: #F5F5F5;
    padding: 50px 0;
}
.auth-bg {
    border-radius: 21px;
    border: 6px solid #FFF;
    box-shadow: 0px 0px 28px 0px rgba(222, 222, 222, 0.50);
}
.auth-lft {
    height: 100%;
    background: url("../../public/images/auth-bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 21px 0 0 21px;
}
.auth-form {
    padding: 30px;
}
.auth-form h2 {
    color: #222;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 19px 0;
}
.form-group label {
    color: #222;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.form-group .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #C0C0C0;
  }
  .form-group .form-control::placeholder {
    color: #C0C0C0;
}
.form-group .form-control {
    border-radius: 5.007px;
    border: 1px solid #DED2D9;
    color: #000;
    font-size: 14.021px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 13px 15px;
    height: auto;
    background-color: #FAF9FE;
}
.form-group h5 {
    text-align: center;
    color: #8C8C8C;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.form-group h5 a {
    color: #F93;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
button.resendBtn {
    color: #F93;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    background: transparent;
}
.submitBtn {
    border-radius: 8px;
    background: #4CAF50;
    border: none;
    padding: 10px 102px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
}
.othersBtn {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 12px 10px;
}
.otp-modal h3 {
    text-align: center;
    color: #182035;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 14px 0;
}
.otp-modal h6 {
    color: #606268;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0 89px;
}
ul.otp-l {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    padding: 0 57px;
}
input.form-control.input-style {
    border-radius: 6px;
    border: 1.5px solid #F93;
    text-align: center;
    padding: 23px 5px;
}
button.clodeBtn {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: transparent;
    font-size: 27px;
    color: red;
}
.dashboard-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
}
.dashboard-header-right-profile {
    display: flex;
    align-items: center;
    gap: 10px;
}
.dashboard-header-right-pic {
    width: 45px;
    height: 45px;
}
.dashboard-header-right-pic img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #ccc;
    padding: 3px;
}
.dashboard-header-right-info h3 {
    margin: 0;
    color: #252733;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
}
.header-bell{
    color: #666;
    font-size: 25px;
}

.header-dropdown {
    background: transparent !important;
    border: none;
    padding: 0;
    color: #1A237E !important;
    font-size: 26px;
}
.header-dropdown.dropdown-toggle::after {
    display: none;
}
.headerdrop_menu {
    margin: 0;
    padding: 0;
    min-width: 202px;
    transform: inherit !important;
    top: 45px !important;
    right: 0 !important;
    left: auto !important;
}
.headerdrop_menu a {
    color: #333;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    border-bottom: 1px solid #DFE0EB;
    display: block;
    padding: 14px 12px;
}
.top-dashboard-header h2 {
    color: #252733;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    margin: 0;
}
.top-dashboard-header {
    padding: 10px 0;
}
.dashboard_menu {
    border-radius: 8px;
    background: #1A237E;
}
.dashboard_menu ul {
    display: flex;
    align-items: center;
}
.dashboard_menu ul a {
    display: block;
    padding: 12px 15px;
    color: #DEDEDE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
.dashboard-panel {
    border-radius: 8px;
    border: 1px solid  #DFE0EB;
    background: #FFF;
    padding: 20px;
    margin: 30px 0 73px 0;
}
.d-box h2 {
    color: #121212;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.d-box h3 {
    margin: 0;
    color: #121212;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.dinfo-box {
    background: #FFF;
    border: 1px solid #DFE0EB;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 13px;
}
.dinfo-box-l {
    width: 52px;
    height: 52px;
    background: #fff7d9;
    border-radius: 50%;
}
.dinfo-box h5 {
    color: #606060;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.dinfo-box b {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.card-listed {
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 0px 28px 0px rgba(222, 222, 222, 0.50);
    margin-bottom: 30px;
}
.card-listed label {
    margin: 0 auto;
    text-align: center;
    display: block;
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #222;
    padding: 10px 10px;
    width: 326px;
    border-radius: 0 0 30px 30px;
}
.card-listed-lft {
    padding: 15px 20px;
}
.card-listed-lft h5 {
    color: #222;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}
.card-listed-lft h3 {
    color: #1A237E;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.btnStyle-one {
    border-radius: 30px;
    border: 1px solid #222;
    color: #222;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: block;
    text-align: center;
    padding: 9px 16px;
    background: transparent;
}
.card-listed-cen span {
    background: #F5F5F5;
    color: #1A237E;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 36px;
    display: inline-block;
}
.card-listed-cen {
    text-align: center;
}
.btnStyle-text {
    display: block;
    text-align: center;
    margin: 0 auto;
    border: none;
    background: transparent;
    color: #FF0505;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 10px;
}
.card-listed-rht {
    padding: 10px 15px;
    text-align: center;
}
.card-listed-rht h5 {
    color: #1A237E;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}

.btnStyle-two {
    border-radius: 8px;
    background: #4CAF50;
    color: #FFF !important;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    display: block;
    padding: 12px 30px;
    margin: 0 auto;
}
.card-listed-rht h3 {
    color: #606060;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    margin: 13px 0;
}
button.btnStyle-three {
    background: #FFD740;
    border: none;
    color: #1A237E;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    margin: 0 auto;
    padding: 9px 18px;
    border-radius: 30px;
}
.btnStyle-three {
    background: #FFD740;
    border: none;
    color: #1A237E;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-block;
    margin: 0 auto;
    padding: 9px 18px;
    border-radius: 30px;
}
.gamerule-modal h3 {
    color: #222;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.game-box {
    border-radius: 24px;
    border: 1px solid rgba(255, 215, 64, 0.80);
    background: rgba(255, 215, 64, 0.20);
    padding: 15px;
}
.rule-box ul li {
    color: #171717;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.3px;
    padding: 6px 0;
}
.point-box {
    border-radius: 24px;
    border: 1px solid rgba(76, 175, 80, 0.80);
    background: rgba(76, 175, 80, 0.10);
    padding: 15px;
}
.rule-box {
    margin-bottom: 30px;
}
.gamerule-modal {
    padding: 50px 15px 15px 15px;
}
.gamerule-modal {
    padding: 50px 15px 15px 15px;
    max-height: 400px;
    overflow-y: scroll;
    min-height: auto;
}
.headerTwo {
    background: #1A237E;
}
.top-dashboard-header.headerTwo h2 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.top-dashboard-header.headerTwo h5 {
    margin: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.top-dashboard-header.headerTwo .dashboard-header-right-info h3 {
    color: #fff;
}
.top-dashboard-header.headerTwo .header-bell {
    color: #fff;
}
.back-page {
    background: #53506e;
    padding: 12px 0;
    color: #fff;
}
.back-page button {
    margin: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    background: transparent;
    border: none;
}
.inner-container {
    background: #fff;
    padding: 30px;
}
.leaderboard-top {
    padding: 0 100px;
}
.leaderboard-team {
    display: flex;
    align-items: center;
    gap: 15px;
}
.leaderboard-team {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    width: 40%;
}
.leaderboard-team-img {
    width: 35px;
    height: 35px;
}
.leaderboard-team-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.team-vs {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    justify-content: space-between;
    background: #000;
    min-height: 97px;
    color: #fff;
}
.leaderboard-team h4 {
    margin: 0;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    width: 71%;
}
.card-listed.leaderboard-v {
    box-shadow: none;
    border-top: 1px solid #DFE0EB;
    border-bottom: 3px solid #DFE0EB;
    border-radius: 0;
}
.card-listed.leaderboard-v .card-listed-lft span {
    background: #FF9933;
    color: #fff;
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-block;
    padding: 10px 26px;
    margin-bottom: 14px;
}
.card-listed.leaderboard-v .card-listed-lft {
    padding: 0;
}
.card-listed-select select.form-control {
    width: 300px;
    margin: 0 auto 0 auto;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    color: #1A237E;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.leader-tab {
    width: 835px;
    margin: 0 auto;
    max-width: 100%;
}
.leader-tab h5 {
    color: #9C9C9C;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-transform: capitalize;
    text-align: center;
    margin: 0 0 14px 0;
}
.leader-tab h5 span {
    width: 32px;
    height: 32px;
    color: #1A237E;
    background: #F5F5F5;
    border: 1px solid #DEDEDE;
    display: inline-flex;
    border-radius: 50%;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}
.leader-tab-share {
    width: 500px;
    margin: 30px auto;
    max-width: 100%;
    position: relative;
}
.leader-tab-share input {
    border-radius: 8px;
    border: 1px solid rgba(255, 215, 64, 0.80);
    background: rgba(255, 215, 64, 0.20);
    width: 100%;
    color: #8C8C8C;
    text-align: left;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    padding: 10px 22px;
}
.leader-tab-share button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 8px;
    background: #1A237E;
    bottom: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    padding: 5px 19px;
}
.leader-tab table {
    width: 100%;
}
.leader-tab table th {
    background: #108714;
    color: #fff;
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px;
    list-style: telugu;
}
.leader-tab table td {
    color: #333;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #EDEDED;
    padding: 10px;
}
.leader-tab .nav-tabs {
    border-radius: 30px;
    background: #FFF;
    box-shadow: 1px 1px 17px 10px #F0F0F0;
    width: 326px;
    display: flex;
    justify-content: center;
    margin: 0 auto 20px auto;
    border: none;
}
.leader-tab .nav-tabs li button {
    color: #787785;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    background: transparent;
    padding: 15px 22px;
    border-radius: 30px;
    width: 100%;
    text-align: center;
}
.leader-tab .nav-tabs li button.active {
    background: #092133;
    color: #fff;
}
.leader-tab .nav-tabs li.nav-item {
    flex: 1;
}
.team-container {
    background: url("../../public/images/createTeambg.png");
    background-size: cover;
    background-position: center;
    padding: 50px 0;
}
.team-container h5 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.team-container h6 {
    margin: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.matchoverview-i {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    color: #fff;
    margin-bottom: 18px;
    border-radius: 5px;
    padding: 10px;
    background: rgba(0,0,0,0.7);
}
.team-cnt {
    display: flex;
    align-items: center;
    gap: 15px;
    /* justify-content: center; */
    width: 43%;
}
.team-cnt-img {
    width: 40px;
    height: 40px;
}
.team-cnt-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.team-cnt-info h4 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}
.team-cnt-info b {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.team-container ul {
    display: flex;
    justify-content: center;
    gap: 7px;
    margin: 44px 0 12px 0;
}
.team-container ul li {
    border-radius: 4px;
    background: #4CAF50;
    width: 48px;
    height: 24px;
}
.team-container span {
    text-align: center;
    display: block;
    color: #999;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
}
.team-info {
    background: #222;
    padding: 17px 0;
}
.team-info ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.team-info ul li {
    color: #9C9C9C;
    text-align: right;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-transform: capitalize;
}
ul.team-tabs {
    display: flex;
    border: none;
}
ul.team-tabs li.nav-item {
    flex: 1;
}
ul.team-tabs li.nav-item button {
    display: block;
    width: 100%;
    color: #9C9C9C;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    border-bottom: 4px solid transparent;
    border: none;
    background: transparent;
}
ul.team-tabs li.nav-item button.active{
    border-bottom: 4px solid #FF9933;
    color: #FF9933;
}
.tab-info {
    background: #FFF;
    box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.tab-info h5 {
    margin: 0;
    color: #606060;
    text-align: right;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-transform: capitalize;
}
.tab-info h6 {
    margin: 0;
    color: #D13D23;
    text-align: right;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}
.team-td {
    text-align: center;
    width: 93px;
}
.team-td img {
    width: 47px;
}
.team-td span {
    display: block;
    border-radius: 30px;
    background: #f1f1f1;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    text-align: center;
    color: #1A237E;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 5px 22px;
}
.point-part h3 {
    color: #222;
    text-align: left;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.263px;
    margin: 0;
}
.point-part h5 {
    color: #8C8C8C;
    text-align: left;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.point-part h6 {
    margin: 0;
    color: #2B6AF1;
    text-align: left;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.jJhCqB div:first-child {
    color: #666;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}
button.table-btn.delete-rowbtn {
    background: transparent;
    border: none;
    padding: 0;
    color: #D13D23;
    font-size: 23px;
}
table.custom-table {
    width: 100%;
}
.custom-table th {
    color: #000000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    padding: 10px 15px;
    border-bottom: 1px solid #DFE0EB !important;
}
.custom-table td {
    padding: 10px 15px;
    color: #666;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.creatTeamTable th{
   cursor: pointer; 
}
.creatTeamTable tr {
    opacity: 0.7;
}
.creatTeamTable tr:hover {
    opacity: 1;
    background: rgba(255, 215, 64, 0.20);
    cursor: pointer;
}
.creatTeamTable tr.selected {
    opacity: 1;
    background: rgba(255, 215, 64, 0.20);
}
ul.tableBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.outline-btn {
    border-radius: 8px;
    border: 1px solid #4CAF50;
    color: #4CAF50;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
    padding: 12px 30px;
    display: inline-block;
}
.solid-btn {
    border-radius: 8px;
    background: #4CAF50;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    padding: 13px 30px;
    display: inline-block;
}
.select-captain-info {
    width: 700px;
    margin: 0 auto;
    max-width: 100%;
}
.select-captain-info h2 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 0 0 34px 0;
}
.select-captain-card {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.select-captain-card-img img {
    width: 95px;
}
.select-captain-card-img label {
    margin: 0;
    display: block;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 5.33333px 21.33333px 0px rgba(0, 0, 0, 0.10);
    color: #1A237E;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 7px 15px;
}
.select-captain-card-info h4 {
    color: #FFF;
    text-align: left;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.263px;
}
.select-captain-card-info b {
    color: #FF0101;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.select-captain-card-info {
    margin-top: 30px;
}
.radio-style {
    position: relative;
    margin-bottom: 4px;
}
.radio-style input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
}
.radio-style button {
    margin: 0;
    width: 50px;
    height: 50px;
    border: 1px solid #DEDEDE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.radio-style input[type="radio"]:checked + label {
background: #4CAF50;
color: #fff;
}
ul.dashboard-tab-menu.nav.nav-tabs {
    justify-content: flex-start;
    border: none;
    background: #fff;
    box-shadow: 0 0 17px 2px rgba(0,0,0,0.125);
    width: 343px;
    border-radius: 30px;
    margin: 50px auto 30px auto;
}
ul.dashboard-tab-menu.nav.nav-tabs li.nav-item {
    flex: 1;
}
ul.dashboard-tab-menu.nav.nav-tabs li.nav-item button {
    border-radius: 30px;
    background: transparent;
    text-align: center;
    margin: 0;
    border: none;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 9px 20px;
}
ul.dashboard-tab-menu.nav.nav-tabs li.nav-item button.active {
    background: #4caf50;
    color: #fff;
}
.my-team-box-top ul {
    display: flex;
    align-items: center;
    gap: 15px;
}
.my-team-box-top ul button {
    padding: 0;
    background: transparent;
    border: none;
}
.my-team-box-top h4 {
    color: #222;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.263px;
    margin: 0;
}
.my-team-box-bottom {
    border-radius: 0 0 10px 10px;
    background: #F5F5F5;
    padding: 15px;
    position: relative;
}
.my-team-box {
    border: 1px solid #e7e7e7;
    border-radius: 24px;
    margin-bottom: 30px;
}
.my-team-box-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
}
.my-team-box-img img {
    width: 101px;
    height: 126px;
    object-fit: cover;
}
.my-team-box-img label {
    margin: 0;
    display: block;
    text-align: center;
    border-radius: 666.667px;
    background: #222;
    box-shadow: 0px 5.333px 21.333px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 7px 10px;
}
.my-team-box-img span {
    position: absolute;
    top: 49px;
    right: 21px;
    width: 32px;
    height: 32px;
    background: #333;
    color: #fff;
    font-weight: 700;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
}
.my-tem-s h6 {
    color: #222;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.my-tem-s {
    text-align: center;
}
.my-tem-s b {
    color: #222;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.my-team-box-bottom ul {
    position: absolute;
    bottom: 0;
    display: flex;
    right: 0;
    border-radius: 24px 0px;
    background: #1A237E;
}
.my-team-box-bottom ul li {
    color: #FFF;
    text-align: right;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding: 9px 18px;
}
.liveTxt span {
    color: #00C105;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    /* color: var(--color-primary);
    -webkit-animation: shadows 1.2s ease-in infinite;
            animation: shadows 1.2s ease-in infinite; */
    letter-spacing: 0.4rem;
}
.live-info {
    background: #222;
    color: #fff;
    padding: 14px 0;
}
.live-info label {
    margin: 0 20px 0 0;
    color: #F9F9F9;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.live-info ul {
    display: flex;
    gap: 20px;
    align-items: center;
}
.live-info ul li span {
    border: 1px solid #ccc;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #FFF;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.live-info ul li span.six {
    background: #007E03;
}
.live-info ul li span.four {
    background: #e7b500;
}
.live-info ul li span.wicket  {
    background: #ff0000;
}

.live-info ul li span.wide  {
    background: #ffc400;
}

  
  @-webkit-keyframes shadows {
    0% {
      text-shadow: none;
    }
    10% {
      transform: translate(-3px, -3px);
      text-shadow: 3px 3px 0 var(--color-secondary);
    }
    20% {
      transform: translate(-6px, -6px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary);
    }
    30% {
      transform: translate(-9px, -9px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }
    40% {
      transform: translate(-12px, -12px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    }
    50% {
      transform: translate(-12px, -12px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    }
    60% {
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    }
    70% {
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }
    80% {
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary);
    }
    90% {
      text-shadow: 3px 3px 0 var(--color-secondary);
    }
    100% {
      text-shadow: none;
    }
  }
  
  @keyframes shadows {
    0% {
      text-shadow: none;
    }
    10% {
      transform: translate(-3px, -3px);
      text-shadow: 3px 3px 0 var(--color-secondary);
    }
    20% {
      transform: translate(-6px, -6px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary);
    }
    30% {
      transform: translate(-9px, -9px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }
    40% {
      transform: translate(-12px, -12px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    }
    50% {
      transform: translate(-12px, -12px);
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    }
    60% {
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    }
    70% {
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }
    80% {
      text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary);
    }
    90% {
      text-shadow: 3px 3px 0 var(--color-secondary);
    }
    100% {
      text-shadow: none;
    }
  }

  .player_info {
    display: flex;
    align-items: center;
    gap: 25px;
}
.player_info_lft img {
    width: 61px;
}
.player_info_lft {
    width: 16%;
    text-align: center;
}
.player_info_lft span {
    padding: 3px 19px;
    border-radius: 500px;
    background: #222;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    text-align: center;
    text-transform: uppercase;
}
.player_info_rht h4 {
    color: #222;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.263px;
}
.player_info_rht span {
    color: #8C8C8C;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.scorboard-acc button.accordion-button {
    width: 100%;
    border: none;
    background: rgba(255, 215, 64, 0.20);
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
}
.scorboard-acc button.accordion-button label {
    margin: 0;
    color: #222;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.scorboard-acc button.accordion-button ul {
    display: flex;
    align-items: center;
    gap: 20px;
}
.scorboard-acc button.accordion-button ul li {
    color: #606060;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
table.scorboard-table {
    width: 100%;
}
table.scorboard-table th {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 10px;
    background: #f2f2f2;
}
table.scorboard-table td {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px 10px;
    border-bottom: 1px solid #efefef;
}
table.scorboard-table td h5 {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
table.scorboard-table td h6 {
    color: #7D7D7D;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.scorboard-acc.accordion h2.accordion-header {
    margin: 0;
}
.subscription-box {
    background: #FFF;
    border: 0.755px solid rgba(0, 114, 201, 0.30);
    border-radius: 10px;
    padding: 15px;
    position: relative;
    min-height: 544px;
    margin-bottom: 30px;
}
.subscription-box h3 {
    color: #1A237E;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.subscription-box p {
    color: #696D6F;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.607px;
    min-height: 102px;
}
.subscription-box h2 {
    color: #333;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    border-bottom: 1px solid #DEDEDE;
    padding: 10px 0 12px 0;
}
.subscription-btn {
    background: #f2f2f2;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    border: none;
    text-align: center;
    padding: 11px 15px;
    border-radius: 5px;
    width: 100%;
    display: block;
    margin-bottom: 18px;
}
.subscription-btn.basic{
    background: #4CAF50;
}
.subscription-btn.silver{
    background: #FFD740;
    color: #000;
}
.subscription-btn.gold{
    background: #FF9933;
}
.subscription-btn.platinum{
    background: #0051CA;
}
.subscription-box ul li {
    color: #333;
    font-family: Montserrat;
    font-size: 12.078px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    padding: 7px 5px 7px 18px;
    border-top: 1px solid #DEDEDE;
    text-transform: capitalize;
}
.subscription-box ul li span {
    position: absolute;
    top: 6px;
    left: 0;
    color: rgba(0, 114, 201, 0.50);
}
.subscription-box label {
    position: absolute;
    top: -21px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    background: #FF0101;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    display: block;
    width: 124px;
    padding: 7px 10px;
}
.withdraw-sec h2 {
    color: #252733;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
}
.withdraw-sec h5 {
    color: #606060;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.withdraw-amount-box {
    display: flex;
    align-items: center;
    gap: 30px;
    border-top: 1px solid #DFE0EB;
    padding: 15px 0;
    margin: 20px 0;
}
.withdraw-amount-box h4 {
    color: #222;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    margin: 0;
    width: 43%;
}
.withdraw-amount-box .form-control {
    width: 200px !important;
    flex: inherit;
}
.withdraw-history-form label {
    color: #606060;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.withdraw-history-form input.form-control {
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    padding: 9px 10px;
    height: auto;
}
button.bluesolidBtn {
    border-radius: 8px;
    background: #1A237E;
    border: none;
    color: #fff;
    display: block;
    padding: 9px 33px;
}
button.csvBtn {
    border: navajowhite;
    padding: 0;
    background: transparent;
    color: #606060;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
table.history-table {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #DFE0EB;
    background: #FFF;
}
table.history-table th {
    color: #2D2D2D;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.3px;
    background: #d7e4f7;
    padding: 13px 10px;
}
table.history-table td {
    color: #2D2D2D;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.3px;
    padding: 13px 10px;
}
table.history-table tbody tr:nth-child(even) {
    background: #ECECEC;
}
.dashboard_menu ul a.active {
    background: #151C65;
}
.headerdrop_menu a.active {
    background: #151C65;
    color: #fff;
}
.point-history button.accordion-button {
    border-radius: 8px;
    background: #F5F5F5;
    width: 100%;
    border: none;
    color: #1A237E;
    text-align: left;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 13px 15px;
    position: relative;
}
.point-history ul {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #DFE0EB;
}
.point-history h2{
    margin: 0;
}
.point-history button.accordion-button.collapsed::before {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 15px;
    color: #0E0E0E;
}
.point-history button.accordion-button::before {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 15px;
    color: #0E0E0E;
}
.upload-profile {
    width: 70%;
    height: 152px;
    position: relative;
}
.upload-profile img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 4px solid #1a237e;
}
.upload-profile button {
    position: absolute;
    right: 0;
    border: none;
    top: 0;
    background: #1a237e;
    color: #fff;
    font-size: 13px;
    padding: 5px 9px;
    border-radius: 0 10px 0 13px;
}
.upload-profile button input[type="file"] {
    position: absolute;
    opacity: 0;
}

.faq-sec .accordion-body {
    letter-spacing: 0px;
    color: #333333;
    font-weight: 400;
    padding: 0 15px;
}
  .faq-sec .accordion-button {
    letter-spacing: 0px;
    color: #333333;
    background-color: white;
    border: 0px;
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
    position: relative;
    font-size: 18px;
    font-weight: 500;
}
  .faq-sec .accordion-item {
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  
  }

  .faq-sec .accordion-button.collapsed::before {
    content: "\f067";
    font-family: 'FontAwesome';
    position: absolute;
    top: 12px;
    right: 11px;
}
.faq-sec .accordion-button::before {
    content: "\f068";
    font-family: 'FontAwesome';
    position: absolute;
    top: 12px;
    right: 11px;
}
  .faq-sec .accordion-item h2.accordion-header {
    margin: 0;
}
button.changeBtn {
    border: none;
    background: transparent;
    text-decoration: underline;
    font-size: 14px;
}
.noti-sec ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 10px;
}
.noti-sec ul li p {
    margin: 0;
    font-size: 14px;
}
.noti-sec ul li span {
    font-size: 13px;
    color: #666;
}
.setting-sec h2 {
    color: #121212;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.setting-sec h5 {
    color: #8C8C8C;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
.setting-sec button {
    border: none;
    background: transparent;
    color: #000;
    font-weight: 500;
    padding: 0;
}
.setting-sec table {
    width: 433px;
    max-width: 100%;
}
.setting-sec table th {
    background: #108714;
    color: #fff;
    font-size: 14px;
    padding: 10px 11px;
    border: 1px solid #ccc;
}
.setting-sec table td {
    color: #666;
    font-size: 14px;
    padding: 10px 11px;
    border: 1px solid #ccc;
}
.team-preview-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.team-preview-info ul {
    display: flex;
    gap: 30px;
}
.team-preview-info ul li span {
    display: inline-block;
    padding: 2px 20px;
    background: #fff;
    color: #222;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
}
.team-preview-info ul li:nth-child(2) span {
    background: #FFD740;
}
.team-preview-info ul li:last-child {
    border-left: 2px solid #DFE0EB;
    padding-left: 29px;
}
.team-preview{
    background: url("../../public/images/team-preview-bg.png");
    background-size: cover;
    padding: 70px 0;
}
.team-preview-inner{
    background: url("../../public/images/team-inner-preview-bg.png");
    background-size: cover;
    min-height: 688px;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.peach-container{
    background: url("../../public/images/peach_img.png");
    background-size: cover;
    height: 181px;
    width: 512px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
}
.peach-container h4 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.263px;
    margin: 0;
    writing-mode: vertical-lr;
}
.peach-container h4:first-child {
    margin-left: -55px;
}
.peach-container::before {
    content: "";
    position: absolute;
    top: -21px;
    bottom: -21px;
    width: 1px;
    background: #CACBDB;
    left: 64px;
}
.peach-container::after {
    content: "";
    position: absolute;
    top: -21px;
    bottom: -21px;
    width: 1px;
    background: #CACBDB;
    right: 64px;
}
.team-preview-list li {
    position: absolute;
    width: 100px;
    text-align: center;
    left: 0;
    top: 0;
}
.preview-player-list-img img {
    width: 50px;
}
.preview-player-list label {
    background: #fff;
    padding: 3px 29px;
    border-radius: 30px;
    font-size: 13px;
    margin: 0px 0 4px 0;
}
.preview-player-list span {
    color: #DEDEDE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}
.team-preview-list.wicketkeeper-slot li:first-child {
    bottom: 12%;
    left: 15%;
    top: auto;
}
.team-preview-list.wicketkeeper-slot li:nth-child(2) {
    bottom: auto;
    left: 15%;
    top: 12%;
}

.team-preview-list.wicketkeeper-slot li:nth-child(3) {
    bottom: auto;
    left: 11%;
    top: 40%;
}
.team-preview-list.wicketkeeper-slot li:nth-child(4) {
    left: 6%;
    top: 24%;
    bottom: auto;
}
.team-preview-list.batting-slot li:first-child {
    bottom: auto;
    left: 32%;
    top: 2%;
}
.team-preview-list.batting-slot li:nth-child(2) {
    bottom: auto;
    left: 28%;
    top: 45%;
}

.team-preview-list.batting-slot li:nth-child(3) {
    bottom: 2%;
    left: 32%;
    top:auto;
}
.team-preview-list.batting-slot li:nth-child(4) {
    bottom: 18%;
    left: 33%;
    top: auto;
}
.team-preview-list.batting-slot li:nth-child(5) {
    bottom: auto;
    left: 33%;
    top: 16%;
}
.team-preview-list.ar-slot li:first-child {
    bottom: 23%;
    left: 49%;
    top: auto;
}
.team-preview-list.ar-slot li:nth-child(2) {
    bottom: auto;
    left: 49%;
    top: 23%;
}

.team-preview-list.ar-slot li:nth-child(3) {
    bottom: auto;
    left: auto;
    top: 5%;
    right: 34%;
}
.team-preview-list.ar-slot li:nth-child(4) {
    bottom: 4%;
    left: auto;
    top: auto;
    right: 30%;
}
.team-preview-list.bowl-slot li:first-child {
    bottom: auto;
    left: auto;
    top: 45%;
    right: 9%;
}
.team-preview-list.bowl-slot li:nth-child(2) {
    bottom: 14%;
    left: auto;
    top: auto;
    right: 17%;
}
.team-preview-list.bowl-slot li:nth-child(3) {
    bottom: auto;
    left: auto;
    top: 8%;
    right: 20%;
}
.team-preview-list.bowl-slot li:nth-child(4) {
    bottom: auto;
    left: auto;
    top: 25%;
    right: 7%;
}
.team-preview-list.bowl-slot li:nth-child(5) {
    bottom: 23%;
    left: auto;
    top: auto;
    right: 7%;
}
.team-preview-inner h3 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.263px;
    writing-mode: vertical-lr;
    text-orientation: inherit;
    margin: 0 0 0 0;
    position: absolute;
    left: 79px;
}
.contest-modal h3 {
    color: #222;
    text-align: center;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.37px;
    background: #f1f1f1;
    padding: 10px;
    margin: -15px -15px 19px -15px;
}
ul.purchase-c {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
ul.purchase-c button {
    border-radius: 8px;
    border: 1px solid #ECECEC;
    background: #FFF;
    box-shadow: 0px 6px 12px 0px rgba(105, 105, 105, 0.10);
    padding: 13px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 186px;
}
ul.purchase-c button label {
    margin: 0;
    color: #606060;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
}
ul.purchase-c button span {
    color: #0F55BD;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
}
.withdraw-amount-box.purchase {
    width: 666px;
    margin: 20px auto;
    max-width: 100%;
}
.cupon-code-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 26px 0;
}
.cupon-code-container label {
    margin: 0;
    color: #333;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.cupon-field button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
    border-radius: 8px;
    background: #1A237E;
    color: #FFF;
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 4px 25px;
}
.cupon-field input.form-control {
    border-radius: 8px;
    border: 1px solid rgba(255, 215, 64, 0.80);
    background: rgba(255, 215, 64, 0.20);
    width: 303px;
    padding: 9px 15px;
    height: auto;
}
.payment-page h2 {
    color: var(--grayscale-black, #252733);
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
}
.cupon-field {
    position: relative;
}
ul.card-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
}
.d-right {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
}
.d-box h3 a {
    display: inline-block;
    background: #1A237E;
    color: #fff;
    padding: 5px 12px;
    border-radius: 2px;
    margin-left: 14px;
}
.dashboard-panel-item ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.dashboard-panel-item ul li {
    width: 32.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px 10px;
}
.dashboard-panel-item ul li a.nav-link {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #212529;
}
.dashboard-tabs-menu {
    display: block;
}
.dashboard-tabs-menu li {
    padding: 0 !important;
}
.dashboard-tabs-menu li a.nav-link{
    padding: 15px 10px !important;
}
.dashboard-tabs-menu li a.nav-link.active{
    color: #fff;
    background-color: #1a237e;
}
.dashboard-card-listed {
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 0px 28px 0px rgba(222, 222, 222, 0.50);
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
}
.dashboard-card-listed h5 {
    text-align: center;
    margin: 0 0 23px 0;
    font-size: 20px;
}
.dashboard-card-listed-lft {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin-bottom: 24px;
}
.dashboard-card-listed-lft img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.dashboard-card-listed-lft label {
    margin: 0;
    font-weight: 600;
}
.dashboard-card-listed-cen .btnStyle-two {
    display: inline-block;
}
.dashboard-card-listed-cen {
    text-align: center;
}
.dashboard-card-listed-cen span {
    display: block;
    color: #b31616;
    margin-top: 8px;
}
span.info-text {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #151c65;
    color: #fff;
    font-size: 15px;
    padding: 5px 10px;
}
.dashboard-panel-item .dashboard-earning-view li {
    width: 19%;
    border: none;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
}
.dashboard-earning-view table {
    width: 100%;
}
.earning-user-img {
    width: 30px;
    height: auto;
}
.dashboard-earning-view table td {
    font-size: 13px;
    border: 1px solid #ccc;
    padding: 10px;
}
.dashboard-earning-view table th {
    padding: 10px;
    font-size: 14px;
    background: #f2f2f2;
    border: 1px solid #ccc;
}
.table-btn {
    border: none;
    padding: 0;
    background: transparent;
}
.jJhCqB div:first-child {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}
.table-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}
.table-top ul{
    display: flex;
    gap: 10px;
}
ul.admin-ern-table {
    display: flex;
    gap: 10px;
}
ul.admin-ern-table li {
    flex: 1;
    font-size: 14px;
    padding: 7px 0;
}
ul.admin-ern-table > li{
    cursor: pointer;
}
table.admin-ern-table-inner-table {
    width: 100%;
    border: 1px solid #ccc;
}
table.admin-ern-table-inner-table td {
    font-size: 13px;
}
.jJhCqB div:first-child {
    text-overflow: initial !important;
    overflow: visible !important;
}
.admin-list-menu ul {
    height: 400px;
    overflow-y: scroll;
}

.loader-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.9);
    z-index: 99999;
}
.loader {
    width: 100px;
    aspect-ratio: 1;
    display: grid;
  }
  .loader:before,
  .loader:after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: #4CAF50;
    -webkit-mask:repeating-linear-gradient(#000 0 5px,#0000 0 10px);
    animation: l35 1.5s infinite;
  }
  .loader:after {
    -webkit-mask:repeating-linear-gradient(#0000 0 5px,#000 0 10px);
    --s:-1;
  }
  @keyframes l35 {
    0%,
    10% {transform: translate(0) rotate(0)}
    35% {transform: translate(calc(var(--s,1)*50%)) rotate(0)}
    66% {transform: translate(calc(var(--s,1)*50%)) rotate(calc(var(--s,1)*180deg))}
    90%,
    100% {transform: translate(0) rotate(calc(var(--s,1)*180deg))}
  }
  .dashboard-header-logo {
    display: flex;
    align-items: center;
    gap: 20px;
}
.dashboard-header-logo img {
    width: 89px;
}
.copyright {
    text-align: center;
    font-size: 14px;
    background: #fff;
    padding: 10px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.card__skeleton {
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(to right, rgb(237, 237, 237), rgb(255, 255, 255));
  }
  
  .card__skeleton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    height: 100%;
    animation: shine infinite 1.5s ease-in-out;
  }
  
  @keyframes shine {
    from {
        transform: skew(2deg) translateX(0%);
    }
  
    to {
        transform: skew(2deg) translateX(200%);
    }
  }

  .card__title {
    height: 12px;
    margin-bottom: 7px;
    border-radius: 40px;
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: 100%;
}
.custom-table td:last-child {
    white-space: nowrap;
}
button.dchangeBtn {
    position: absolute;
    right: 24px;
    top: 36px;
    border: none;
    background: #222;
    font-size: 14px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
}
ul.pagination {
    justify-content: flex-start;
    gap: 10px;
    margin: 10px 0;
    max-width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    flex-flow: row;
    padding: 10px 0;
}
.pagination li button {
    border: 1px solid #000;
    background: transparent;
    padding: 2px 13px;
    height: auto;
    border-radius: 3px;
  }
  .pagination li button:hover{
    background: #000;
    color: #fff;
  }
  .pagination button.active {
    background: #000;
    color: #fff;
    pointer-events: none;
}
  .form-control.dropdown-toggle {
    text-align: left;
    background: #FAF9FE !important;
    color: #000 !important;
}
.custom-dropdown-checkbox .dropdown-menu {
    width: 100%;
    margin: 0;
    padding: 0;
}
.custom-dropdown-checkbox .dropdown-menu li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 3px 10px;
}
.custom-dropdown-checkbox .dropdown-menu li label{
    margin: 0;
}
.after-l  a {
    color: #fff;
    font-size: 14px;
}
.point-history ul li {
    flex: 1;
}
.point-history ul li:nth-child(2) {
   text-align: center;
}
.point-history ul li:last-child {
    text-align: right;
 }
 .logout-li button.logoutBtn {
    border: none;
    width: 100%;
}
.success-sec {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.success-box {
    text-align: center;
}
span.howout {
    font-size: 14px;
    color: #FF9933;
}
.fallwk {
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
}
.fallwk li {
    /* flex: 1; */
    white-space: nowrap;
    font-size: 14px;
}
span.Activeplan {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center;
    background: orange;
    width: 62px;
    margin: 0 auto;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
}
ul.purchase-c.change-plan  button {
    flex-direction: column;
    text-align: left;
    width: auto;
}
ul.match-winner-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}
.match-winner-list label {
    margin: 10px 0 10px 0;
    display: block;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
}
.match-winner-list span {
    display: block;
    margin: 0 0 6px 0;
}
ul.match-winner-list li {
    text-align: center;
    padding: 31px;
    border: 1px solid #ccc;
    width: 40%;
    position: relative;
}
.match-winner-list h5 {
    position: absolute;
    top: 0;
    left: 0;
    background: #323232;
    color: #fff;
    font-size: 13px;
    padding: 4px 7px;
}
.image-vs {
    display: flex;
    justify-content: space-between;
}
.image-vs img {
    width: 65px;
}
ul.match-winner-list li button {
    padding: 5px 24px;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 5px;
    color: #000;
}
ul.match-winner-list li button:hover{
    background: #222;
    color: #fff;
}
ul.match-winner-list li button.active{
    background: #222;
    color: #fff;
}
.header-bell {
    position: relative;
}
.header-bell span {
    position: absolute;
    top: -5px;
    right: -10px;
    background: #1a237e;
    color: #fff;
    font-size: 13px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-scroe th {
    padding: 10px;
    background: #f2f2f2;
}
.table-scroe .player_info_lft img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.table-scroe td {
    padding: 10px;
}
.player_info_rht h4 {

    font-size: 17px;
    margin: 0;
}
.dashboard_main_tab ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard_main_tab ul button {
    border: none;
    background: #746f6f;
    color: #fff;
    font-weight: 500;
    padding: 9px 29px;
    font-size: 15px;
}
.dashboard_main_tab ul button.active {
    background: #4caf50;
    pointer-events: none;
}

.dashboard_main_tab ul li:first-child button{
    border-radius: 30px 0 0 30px;
}
.dashboard_main_tab ul li:last-child button{
    border-radius: 0px 30px 30px 0px;
}
.dashboard-form {
    width: 720px;
    margin: 24px auto 0 auto;
    max-width: 100%;
}
ul.game-info {
    text-align: center;
}
ul.fantsy-match-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 33px;
}
.match-list-box {
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid #ccc;
    min-height: 159px;
}
.match-list-box-t {
    display: flex;
    gap: 26px;
    align-items: center;
    margin: 0 0 10px 0;
}
.match-list-box-tl {
    display: flex;
    gap: 10px;
    align-items: center;
}
.match-list-box label {
    margin: 0;
    display: block;
    text-align: center;
}
ul.fantsy-match-list li {
    width: 32%;
}
.separate {
    border: 1px solid rgba(0,0,0,.1);
    /* padding: 10px; */
    border-radius: 5px;
    margin: 10px 0;
}
.separate h4 {
    font-size: 17px;
}
.memberShip-plan-details p {
    margin: 0;
}
.team-match-i img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.team-match-i {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);
}
.matchoverview-i .team-cnt:last-child {
    justify-content: flex-end;
}
.image-vs span {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.scroll-table {
    min-height: auto;
    overflow-y: scroll;
    max-height: 350px;
}
.hori-scroll {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
}

.hori-scroll::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  .hori-scroll::-webkit-scrollbar-track {
    background: #222;
    border-radius: 8px;
  }
  
  /* Handle */
  .hori-scroll::-webkit-scrollbar-thumb {
    background: #53506e;
    border-radius: 8px;
  }
  ul.step-wizard {
    display: flex;
    justify-content: center;
    /* gap: 53px; */
    margin: 0 0 24px 0;
    width: 400px;
    margin: 0 auto 24px auto;
}
ul.step-wizard li {
    flex: 1;
    position: relative;
    z-index: 1;
}
ul.step-wizard li::before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    height: 1px;
    background: #ccc;
    z-index: -1;
}
ul.step-wizard li:last-child::before{
    display: none;
}